import "jspdf-autotable";

import AssetsApi from "@ROM/Assets/api";

export const buildHeader = async (pdf, documentTitle, company, customer, orderAttributes, date) => {
  const companyLogo = company.attributes.logo
    ? await AssetsApi.find(company.attributes.logo.id)
        .then((response) => response.data.data.attributes.file)
        .catch(() => null)
    : null;

  if (companyLogo) {
    const img = new Image();
    img.src = companyLogo.url;
    pdf.addImage(img, "png", 15, 15, 40, 0);
  } else {
    pdf.text(company.attributes.name, 15, 25);
  }
  pdf.setFontSize(9);
  pdf.text(`${company.attributes.address1}${company.attributes.address2 ? `, ${company.attributes.address2}` : ""}`, 15, 35);
  pdf.text(
    `${company.attributes.city}${company.attributes.state ? `, ${company.attributes.state}` : ""}${
      company.attributes.zip ? ` ${company.attributes.zip}` : ""
    }`,
    15,
    40
  );
  pdf.text(`${company.attributes.phone}`, 15, 45);
  pdf.setFontSize(14);
  pdf.text(documentTitle, 200, 20, { align: "right" });

  let customerInfoY = 25;
  pdf.setFontSize(9);
  pdf.text(`Customer: ${customer.attributes.name || ""}`, 200, customerInfoY, { align: "right" });
  pdf.text(`Sales Order #: ${orderAttributes.invoiceNumber || ""}`, 200, (customerInfoY += 5), { align: "right" });
  pdf.text(`PO #: ${orderAttributes.poNumber}`, 200, (customerInfoY += 5), { align: "right" });
  pdf.text(`Date: ${date}`, 200, (customerInfoY += 5), { align: "right" });

  return customerInfoY;
};

export const buildAdresses = async (pdf, billingAddress, shippingAddress) => {
  // Billing Address
  const billingAddressX = 30;
  let billingAddressY = 55;
  pdf.setFontType("bold");
  pdf.text("BILL\nTO", billingAddressX - 10, 60);
  pdf.setFontType("normal");
  pdf.text(`${billingAddress.attributes.customerCompanyName || ""}`, billingAddressX, billingAddressY);
  pdf.text(`${billingAddress.attributes.contact || ""}`, billingAddressX, (billingAddressY += 5));
  pdf.text(`${billingAddress.attributes.address1 || ""}`, billingAddressX, (billingAddressY += 5));
  pdf.text(`${billingAddress.attributes.address2 || ""}`, billingAddressX, (billingAddressY += 5));
  billingAddressY = billingAddress.attributes.address2 ? (billingAddressY += 5) : billingAddressY;
  pdf.text(
    `${billingAddress.attributes.city}${billingAddress.attributes.state ? `, ${billingAddress.attributes.state}` : ""} ${
      billingAddress.attributes.zip
    }`,
    billingAddressX,
    billingAddressY
  );
  pdf.text(billingAddress.attributes.countryName || "", billingAddressX, (billingAddressY += 5));
  pdf.text(`${billingAddress.attributes.phone || ""}`, billingAddressX, (billingAddressY += 5));

  // Shipping Address
  const shippingAddressX = 130;
  let shippingAddressY = 55;
  pdf.setFontType("bold");
  pdf.text("SHIP\nTO", shippingAddressX - 10, 60);
  pdf.setFontType("normal");
  pdf.text(`${shippingAddress.attributes.customerCompanyName || ""}`, shippingAddressX, shippingAddressY);
  pdf.text(`${shippingAddress.attributes.contact || ""}`, shippingAddressX, (shippingAddressY += 5));
  pdf.text(`${shippingAddress.attributes.address1 || ""}`, shippingAddressX, (shippingAddressY += 5));
  pdf.text(`${shippingAddress.attributes.address2 || ""}`, shippingAddressX, (shippingAddressY += 5));
  shippingAddressY = shippingAddress.attributes.address2 ? (shippingAddressY += 5) : shippingAddressY;
  pdf.text(
    `${shippingAddress.attributes.city}${shippingAddress.attributes.state ? `, ${shippingAddress.attributes.state}` : ""} ${
      shippingAddress.attributes.zip
    }`,
    shippingAddressX,
    shippingAddressY
  );
  pdf.text(shippingAddress.attributes.countryName || "", shippingAddressX, (shippingAddressY += 5));
  pdf.text(`${shippingAddress.attributes.phone || ""}`, shippingAddressX, (shippingAddressY += 5));

  return billingAddressY > shippingAddressY ? billingAddressY : shippingAddressY;
};
